import { createContext, useState } from "react";

export const SharedStateContext = createContext();

export const SharedStateProvider = ({ children }) => {
    const [text, setText] = useState("");
    const [textMarePrevista, setTextMarePrevista] = useState("");
    const [dataSet, setDataSet] = useState([]);
    const [dataSetMarePrevista, setDataSetMarePrevista] = useState([]);
    const [generateGraph, setGenerateGraph] = useState(false);
    const [generateGraphMarePrevista, setGenerateGraphMarePrevista] = useState(false);
    const [headerErase, setHeaderErase] = useState(0);
    const [headerEraseMarePrevista, setHeaderEraseMarePrevista] = useState(0);
    const [columns, setColumns] = useState(['Leitura']);
    const [columnsMarePrevista, setColumnsMarePrevista] = useState(['Leitura']);
    const [numColumn, setNumColumn] = useState(1);
    const [numColumnMarePrevista, setNumColumnMarePrevista] = useState(1);
    const [separators, setSeparators] = useState([]);
    const [separatorsMarePrevista, setSeparatorsMarePrevista] = useState([]);
    const [options, setOptions] = useState({
        "nome_estacao": "",
        "latitude_grau": 0,
        "latitude_min": 0,
        "latitude_seg": 0.0,
        "latitude": "N",
        "longitude_grau": 0,
        "longitude_min": 0,
        "longitude_seg": 0.0,
        "longitude": "E",
        "fuso": 0,
        "hora_inicial": "00:00:00",
        "data_inicial": "2000-01-01",
        "intervalo": "00:00:00",
        "n_amostras": 0,
        "fator_escala": 1
    });
    const [step, setStep] = useState(0);
    const [headerLines, setHeaderLines] = useState([]);
    const [headerLinesMarePrevista, setHeaderLinesMarePrevista] = useState([]);
    const [firstIteration, setFirstIteration] = useState(true); // Controle da primeira iteração
    const [extraData, setExtraData] = useState(null); // Estado para salvar na primeira iteração

    // Estados para gerenciar os dados fornecidos pelo usuário e os resultados da API em Calculator
    const [bodyCalculator, setBodyCalculator] = useState({}); // Estado que armazena os dados do arquivo de constantes harmônicas
    const [responseDataCalculator, setResponseDataCalculator] = useState(null); // Armazena os dados retornados pela API
    const [calcCalculator, setCalcCalculator] = useState(false); // Estado para controlar quando realizar o cálculo
    const [middleLevelCalculator, setMiddleLevelCalculator] = useState(0);// Armazena o valor do NMML fornecido pelo usuário
    const [omegaM2Calculator, setOmegaM2Calculator] = useState(0.0001); // Armazena o valor de ωM2 fornecido pelo usuário

    const nextComponent = () => {
        setStep((prevStep) => (prevStep + 1) % 4); // 4 é o número de componentes
    };

    const value = {
        text,
        setText,
        dataSet,
        setDataSet,
        generateGraph,
        setGenerateGraph,
        headerErase,
        setHeaderErase,
        columns,
        setColumns,
        numColumn,
        setNumColumn,
        separators,
        setSeparators,
        options,
        setOptions,
        step,
        setStep,
        nextComponent,
        headerLines,
        setHeaderLines,
        textMarePrevista,
        setTextMarePrevista,
        generateGraphMarePrevista,
        setGenerateGraphMarePrevista,
        headerEraseMarePrevista,
        setHeaderEraseMarePrevista,
        columnsMarePrevista,
        setColumnsMarePrevista,
        numColumnMarePrevista,
        setNumColumnMarePrevista,
        separatorsMarePrevista,
        setSeparatorsMarePrevista,
        dataSetMarePrevista,
        setDataSetMarePrevista,
        headerLinesMarePrevista,
        setHeaderLinesMarePrevista,
        firstIteration,
        setFirstIteration,
        extraData,
        setExtraData,
        bodyCalculator,
        setBodyCalculator,
        responseDataCalculator,
        setResponseDataCalculator,
        calcCalculator,
        setCalcCalculator,
        middleLevelCalculator,
        setMiddleLevelCalculator,
        omegaM2Calculator,
        setOmegaM2Calculator
    }

    return <SharedStateContext.Provider value={value}>{children}</SharedStateContext.Provider>
}