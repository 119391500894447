import React from "react";
import styles from "../styles/sharedStyles.module.css";
import Karine from "../source/karine.webp";
import Italo from "../source/italo.webp";
import SBHIDRO2024 from "../source/SBHIDRO2024.pdf";
import Tutorial from "../source/Tutorial de uso Tide Editor 2.0.pdf";

function InfoProject() {
    return (
        <div className={`${styles.container} ${styles.main_app}`}>
            <div>
                <h1 className={styles.h1_InfoProject}>Sobre o projeto</h1>
                <p className={styles.p_InfoProject}>
                    O Tide Editor é um sistema desenvolvido com o objetivo de otimizar o controle de qualidade e o processamento de dados maregráficos.
                    Projetado para atender às necessidades de profissionais da área de batimetria, o sistema oferece uma solução acessível e eficiente.
                    Com uma interface intuitiva, ele disponibiliza funcionalidades como a visualização gráfica de dados, a classificação do tipo de maré e o cálculo do Nível de Redução (NR) com base no critério de Courtier.
                </p>
            </div>

            <div>
                <h1 className={styles.h1_InfoProject}>Equipe</h1>
                <div className={styles.teamContainer}>
                    <div className={styles.member}>
                        <img
                            src={Karine}
                            className={styles.profileImage}
                            alt="Karine Barbosa Pinheiro"
                        />
                        <div>
                            <strong className={styles.nameText}>Karine Barbosa Pinheiro</strong>
                            <br />
                            <span className={styles.roleText}>Principal Pesquisadora e Desenvolvedora </span>
                        </div>
                    </div>
                    <div className={styles.member}>
                        <img
                            src={Italo}
                            className={styles.profileImage}
                            alt="Dr. Ítalo Oliveira Ferreira"
                        />
                        <div>
                            <strong className={styles.nameText}>Dr. Ítalo Oliveira Ferreira</strong>
                            <br />
                            <span className={styles.roleText}>Professor Orientador do Projeto</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h1 className={styles.h1_InfoProject}>Tutoriais e Artigos</h1>
                <p className={styles.p_InfoProject}>
                    Tutoriais e artigos relacionados ao sistema e às práticas maregráficas disponíveis para consulta:
                </p>
                <ul className={styles.link}>
                    <li className={styles.li_link}><a  href={SBHIDRO2024} target="_blank" rel="noopener noreferrer" className={styles.link}>Resumo SBHIDRO 2024</a></li>
                    <li className={styles.li_link}><a href={Tutorial} target="_blank" rel="noopener noreferrer" className={styles.link}>Tutorial em PDF</a></li>
                </ul>
            </div>
            <div>
                <h1 className={styles.h1_InfoProject}>O GPHIDRO</h1>
                <p className={styles.p_InfoProject}>
                    O Grupo de Pesquisa em Hidrografia (GPHIDRO), fundado em 2018, promove a difusão de práticas hidrográficas por meio de cursos, palestras, consultorias e projetos. Equipado com tecnologias de ponta como SBES, MBES, SSS e SBP, o grupo combina teoria e prática, destacando-se pela excelência técnica.
                </p>
            </div>
            <div>
                <h1 className={styles.h1_InfoProject}>Contato</h1>
                <p className={styles.p_InfoProject}>
                    Para mais informações, entre em contato: contato@gphidro.com.br
                </p>
            </div>

        </div>
    );
};

export default InfoProject;