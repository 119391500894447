import React from "react";
import Modal from "react-modal";
import styles from "../styles/sharedStyles.module.css"

function StatisticsModal({ openModalStatistics, closeModalStatistics, rawStatistics, editedStatistics }) {
    return (
        <Modal
            isOpen={openModalStatistics}
            onRequestClose={closeModalStatistics}
            contentLabel="Estatísticas"
            className={styles.modalContent}
        >
            <div className={styles.div_button_modal_statistic}>
                <button onClick={closeModalStatistics}>X</button>
            </div>

            <div>
                <h2 className = {styles.h2_Statistics}>Estatísticas Básicas</h2>
                <hr className={styles.hr_header} />
                <br></br>
                <h3 className = {styles.h3_Statistics}>Dados Brutos</h3>
                {rawStatistics ? (
                    <ul className={styles.ul_statistic}>
                        <li className={styles.li}>Média: <span className={styles.span}> {rawStatistics?.statistics?.mean.toFixed(2)} </span></li>
                        <li className={styles.li}>Mediana: <span className={styles.span}> {rawStatistics?.statistics?.median.toFixed(2)} </span></li>
                        <li className={styles.li}>Máximo: <span className={styles.span}> {rawStatistics?.statistics?.max.toFixed(2)} </span></li>
                        <li className={styles.li}>Mínimo: <span className={styles.span}> {rawStatistics?.statistics?.min.toFixed(2)} </span></li>
                        <li className={styles.li}>Variância: <span className={styles.span}> {rawStatistics?.statistics?.variance.toFixed(2)} </span></li>
                        <li className={styles.li}>Desvio Padrão: <span className={styles.span}> {rawStatistics?.statistics?.std_deviation.toFixed(2)} </span></li>
                    </ul>
                ) : (
                    <p>...</p>
                )}

                <br></br>

                <h3 className = {styles.h3_Statistics}>Dados Editados</h3>
                {editedStatistics ? (
                    <ul className={styles.ul_statistic}>
                        <li className={styles.li}>Média: <span className={styles.span}> {editedStatistics?.statistics?.mean.toFixed(2)} </span></li>
                        <li className={styles.li}>Mediana: <span className={styles.span}> {editedStatistics?.statistics?.median.toFixed(2)} </span></li>
                        <li className={styles.li}>Máximo: <span className={styles.span}> {editedStatistics?.statistics?.max.toFixed(2)} </span></li>
                        <li className={styles.li}>Mínimo: <span className={styles.span}> {editedStatistics?.statistics?.min.toFixed(2)} </span></li>
                        <li className={styles.li}>Variância: <span className={styles.span}> {editedStatistics?.statistics?.variance.toFixed(2)} </span></li>
                        <li className={styles.li}>Desvio Padrão: <span className={styles.span}> {editedStatistics?.statistics?.std_deviation.toFixed(2)} </span></li>
                    </ul>
                ) : (
                    <p>...</p>
                )}
            </div>
        </Modal>
    );
}

export default StatisticsModal;

