import moment from "moment";

/**
 * Função para converter um intervalo HH:MM:SS em milissegundos
 * 
 * @param {string} interval Um intervalor em intervalo HH:MM:SS
 * @returns {number} - O numero de milissegundos correspondente
 */
export function parseInterval(interval) {
    const parts = interval?.split(':').map(Number); // Converte cada parte em número
    return (parts[0] * 3600000) + (parts[1] * 60000) + (parts[2] * 1000);
}

/**
 * 
 * @param {string} grau 
 * @param {string} minuto 
 * @param {string} segundo 
 * @param {string} pos 
 * @returns {string} 
 */
export function convertPosicao(grau, minuto, segundo, pos) {
    grau = grau.toString();
    minuto = parseInt(minuto);
    segundo = parseInt(segundo);

    let posicao = "";

    posicao += `${grau.padStart(2, '0')} `;

    let [inteiro, decimal] = (minuto + segundo / 60).toFixed(1).toString().split('.');
    posicao += `${inteiro.padStart(2, '0')}.${decimal}`;

    posicao += ` "${pos}"`

    return posicao;
}

/**
 * Converte em hora no formato HH:MM:SS em hora decimal.
 * 
 * @param {string} horaInicial - hora no formato HH:MM:SS
 * @returns {number} número correspondente a hora informada.
 */
export function converteEmHoraDecimal(horaInicial) {
    // hora, minuto, segundo
    const valor = horaInicial.split(":");
    const hora = parseInt(valor[0], 10);
    const minuto = parseInt(valor[1], 10);
    const segundo = parseInt(valor[2], 10);

    return (hora + minuto / 60 + segundo / 3600).toFixed(4);
}

/**
 * Converte uma data de 'YY-MM-DD' para 'DD MM YY'
 * 
 * @param {string} data Uma data no formato YY-MM-DD
 * @returns {string} Uma string no formato DD MM YY
 */
export function convertData(data) {
    const valor = data.split("-");
    const ano = valor[0];
    const mes = valor[1];
    const dia = valor[2];

    return `${dia} ${mes} ${ano}`;

}

/**
 * Incrementa a data atual com base em um intervalo especificado.
 * 
 * @param {Date} currentDate A data atual que será incrementada.
 * @param {string} intervalo O intervalo de tempo no formato "HH:mm:ss".
 *                           Caso seja "00:00:00", incrementa a data por padrão em 1 hora ("01:00:00").
 */
function incrementDate(currentDate, intervalo) {
    if (intervalo !== "00:00:00") {
        currentDate.setTime(currentDate.getTime() + parseInterval(intervalo));
    } else {
        currentDate.setTime(currentDate.getTime() + parseInterval("01:00:00"));
    }
}

/**
 * Incrementa uma data específica com base em um intervalo de tempo fornecido.
 *
 * @param {Date} baseDate A data inicial que será incrementada.
 * @param {string} interval O intervalo de tempo no formato "HH:mm:ss".
 *                          Caso seja "00:00:00" ou não especificado, o intervalo padrão será de 1 hora ("01:00:00").
 * @returns {Date} Uma nova data com o incremento aplicado.
 *
 * @example
 * const currentDate = new Date("2024-11-22T10:00:00");
 * const newDate = incrementDate(currentDate, "02:30:00");
 *
 * @example
 * const currentDate = new Date("2024-11-22T10:00:00");
 * const newDate = incrementDate(currentDate, "00:00:00");
 */
function normalizeDateTime(dateString, timeString = "00:00:00", fuso) {
    // Define os padrões de regex para diferentes formatos de data
    const dateRegexPatterns = [
        /^(\d{2})\/(\d{2})\/(\d{4})$/, // dd/mm/yyyy
        /^(\d{4})-(\d{2})-(\d{2})$/,  // yyyy-mm-dd
        /^(\d{2})-(\d{2})-(\d{4})$/,  // dd-mm-yyyy
        /^(\d{4})\/(\d{2})\/(\d{2})$/, // yyyy/mm/dd
    ];

    // Define os padrões de regex para diferentes formatos de hora
    const timeRegexPatterns = [
        /^(\d{2}):(\d{2}):(\d{2})$/, // hh:mm:ss
        /^(\d{2}):(\d{2})$/,         // hh:mm
        /^(\d{2})(\d{2})(\d{2})$/,   // hhmmss
    ];

    let day, month, year, hours = "00", minutes = "00", seconds = "00";

    // Normaliza a data
    for (const regex of dateRegexPatterns) {
        const match = dateString.match(regex);
        if (match) {
            if (regex === dateRegexPatterns[0]) {
                // dd/mm/yyyy
                [day, month, year] = [match[1], match[2], match[3]];
            } else if (regex === dateRegexPatterns[1]) {
                // yyyy-mm-dd
                [year, month, day] = [match[1], match[2], match[3]];
            } else if (regex === dateRegexPatterns[2]) {
                // dd-mm-yyyy
                [day, month, year] = [match[1], match[2], match[3]];
            } else if (regex === dateRegexPatterns[3]) {
                // yyyy/mm/dd
                [year, month, day] = [match[1], match[2], match[3]];
            }
            break;
        }
    }

    if (!day || !month || !year) {
        throw new Error(`Formato de data não reconhecido: ${dateString}`);
    }

    // Normaliza a hora
    for (const regex of timeRegexPatterns) {
        const match = timeString.match(regex);
        if (match) {
            if (regex === timeRegexPatterns[0]) {
                // hh:mm:ss
                [hours, minutes, seconds] = [match[1], match[2], match[3]];
            } else if (regex === timeRegexPatterns[1]) {
                // hh:mm
                [hours, minutes] = [match[1], match[2]];
            } else if (regex === timeRegexPatterns[2]) {
                // hhmmss
                [hours, minutes, seconds] = [match[1], match[2], match[3]];
            }
            break;
        }
    }

    if (!hours || !minutes || !seconds) {
        throw new Error(`Formato de hora não reconhecido: ${timeString}`);
    }

    // Caso especial onde algumas estações representam meia noite como 24:mm:ss e não como 00:mm:ss
    if(hours === "24") {
        hours = "00";
    }

    // Cria o objeto Date
    const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

    let dateObject = moment.utc(isoString);

    if(fuso) {
         // Adicionar o ajuste de fuso horário
        dateObject = dateObject.add(fuso, 'hours');
    }

    if (isNaN(dateObject)) {
        throw new Error(`Data inválida após normalização: ${isoString}`);
    }

    return dateObject;
}

function formatDateToISO(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês começa em 0, então somamos 1
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

function formatTimeToISO(date) {
    const hours = String(date.getHours()).padStart(2, '0'); // Hora local
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
}

/**
 * Monta o dataset que será exibido no gráfico.
 *
 * @param {Array} separators Array contendo os separadores das colunas.
 * @param {Object} options Objeto contendo o valor dos campos preenchidos em stationInformation.
 * @param {Array} dataLines Array contendo as linhas do arquivo bruto de dados.
 * @param {Array} columns Array contendo a informação do tipo de cada coluna.
 * @param {Function} setDataSet Setter responsável por atualizar o dataset com o valor processado.
 * @param {number} maxValueGraphic Valor máximo inicial do gráfico.
 * @param {number} minValueGraphic Valor mínimo inicial do gráfico.
 * @param {Function} setMaxValueGraphic Setter responsável por atualizar o valor máximo do gráfico.
 * @param {Function} setMinValueGraphic Setter responsável por atualizar o valor mínimo do gráfico.
 */
export function mountDataset(
    separators,
    options,
    dataLines,
    columns,
    setDataSet,
    maxValueGraphic,
    minValueGraphic,
    setMaxValueGraphic,
    setMinValueGraphic,
) {
    const dataSetAux = [];
    // Calcular valores auxiliares com fallback seguro
    let maxValueAux = maxValueGraphic ?? Number.MIN_VALUE;
    let minValueAux = minValueGraphic ?? Number.MAX_VALUE;

    // Define o regex para separadores. Caso não seja especificado, usa um padrão genérico.
    let regex = separators.length > 0 ? new RegExp(separators.join('|'), 'g') : /\s|\n|\r/g;

    // Define a data inicial com base nas opções ou usa a data/hora atual como padrão.
    let currentDate = options?.data_inicial && options?.hora_inicial
        ? new Date(`${options.data_inicial}T${options.hora_inicial}`)
        : new Date();

    // Itera sobre as linhas de dados para processar cada uma.
    for (let i = 0; i < dataLines.length; i++) {

        let result = dataLines[i].split(regex).filter(Boolean); // Divide a linha em colunas usando o regex.

        // Apenas processa a linha se o número de colunas corresponder ao esperado.
        if (result.length === columns.length) {

            // Caso 1: Apenas uma coluna ('Leitura')
            if (columns.length === 1 && columns[0] === 'Leitura') {

                for (let j = 0; j < result.length; j++) {
                    let value = parseFloat(result[j]);

                    let fullDate = normalizeDateTime(formatDateToISO(currentDate), formatTimeToISO(currentDate), options?.fuso);

                    const d = { index: i, date: fullDate, value: value, authentic: true };

                    if (maxValueAux <= value) maxValueAux = value;
                    if (minValueAux >= value) minValueAux = value;

                    incrementDate(currentDate, options?.intervalo);

                    dataSetAux.push(d);
                }
            }
            // Caso 2: Duas colunas (ex.: 'Leitura' e 'Data', ou 'Leitura' e 'Hora')
            else if (columns.length === 2) {
                let date = '';
                let time = ''
                let value = '';
                let hasTime = false;

                for (let j = 0; j < columns.length; j++) {
                    if (columns[j] === 'Leitura') {
                        value = parseFloat(result[j]);
                    }
                    else if (columns[j] === 'Data') {
                        date = result[j];
                    }
                    else if (columns[j] === 'Hora') {
                        time = result[j];
                        hasTime = true;
                    }
                }

                let fullDate = null;
                if (hasTime) {
                    fullDate = normalizeDateTime(formatDateToISO(currentDate), time,  options?.fuso)
                }
                else {
                    fullDate = normalizeDateTime(date, formatTimeToISO(currentDate),  options?.fuso)
                }

                const d = { index: i, date: fullDate, value: value, authentic: true };

                if (maxValueAux < d.value) maxValueAux = d.value;
                if (minValueAux > d.value) minValueAux = d.value;

                incrementDate(currentDate, options?.intervalo);

                dataSetAux.push(d);
            }
            // Caso 3: Três ou mais colunas 'Leitura', 'Data' e 'Hora').
            else {
                let date = '';
                let time = ''
                let value = '';

                for (let j = 0; j < columns.length; j++) {
                    if (columns[j] === 'Leitura') {
                        value = parseFloat(result[j]);
                    } else if (columns[j] === 'Data') {
                        date = result[j];
                    } else if (columns[j] === 'Hora') {
                        time = result[j];
                    }
                }

                const d = { index: i, date: normalizeDateTime(date, time,  options?.fuso), value: value, authentic: true };

                if (maxValueAux < d.value) maxValueAux = d.value;
                if (minValueAux > d.value) minValueAux = d.value;

                incrementDate(currentDate, options?.intervalo);

                dataSetAux.push(d);
            }
        }
    }

    if (maxValueAux !== Number.MIN_VALUE) {
        setMaxValueGraphic(maxValueAux);
    }

    if (minValueAux !== Number.MAX_VALUE) {
        setMinValueGraphic(minValueAux);
    }

    setDataSet(dataSetAux);
}

/**
 * Função separa o cabeçalho do arquivo salvando as linhas excluídas em headerLines e 
 * a parte sem o cabeçalho em dataLines
 * 
 * @param {Array} columns Array contendo a informação do tipo de cada coluna
 * @param {Array} separators Array contendo os separadores das colunas
 * @param {string} text Texto extraído do arquivo de entrada
 * @param {number} headerErase Número de linhas do cabeçalho a serem excluídas
 * @returns {Object} Um objeto contendo as propriedades headerLines e dataLines
 */
export function separateBodyFromHeader(columns, separators, text, headerErase) {
    let headerLines = [];
    let dataLines = [];

    if (Array.isArray(columns) && 
        Array.isArray(separators) && 
        typeof text === "string" && 
        Number.isInteger(headerErase) &&
        columns.length > 0 &&
        separators.length === (columns.length - 1)) {
        
        const lines = text.replace(/\r/g, '').split("\n");

        headerLines = lines.slice(0, headerErase);
        dataLines = lines.slice(headerErase);
    } else {
        console.error("Erro ao processar texto. Verifique os parâmetros de entrada.");
    }

    return { headerLines, dataLines };
}