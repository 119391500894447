import React, {
    useState,
    useEffect,
    useContext,
    useMemo,
} from "react";

import styles from "../styles/sharedStyles.module.css"

import {
    VictoryChart,
    VictoryLine,
    VictoryScatter,
    VictoryZoomContainer,
    VictoryTheme,
    VictoryAxis,
} from 'victory';

import moment from "moment";

import Modal from "react-modal";

import { SharedStateContext } from "../contexts/SharedStateContext";

import { mountDataset, separateBodyFromHeader } from "../helpers/util";

import DownloadButton from "./downloadButton";
import TextEditor from "./textEditor";
import ColumnDefinition from "./columndefinition";
import ToggleSwitch from "./toggleSwitch";
import StatisticsModal from "./statisticsModal";
import axios from "axios";

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // Importa o CSS do Tippy.js

import IconeBorracha from '../source/borracha.svg';

function Graphic() {

    Modal.setAppElement('#root');

    const [modalGerarMaregraficoIsOpen, setModalGerarMaregraficoIsOpen] = useState(false);
    function openModalGerarMaregrafico() { setModalGerarMaregraficoIsOpen(true); }
    function closeModalGerarMaregrafico() { setModalGerarMaregraficoIsOpen(false); }

    const [modalGerarMarePrevistaIsOpen, setModalGerarMarePrevistaIsOpen] = useState(false);
    function openModalGerarMarePrevista() { setModalGerarMarePrevistaIsOpen(true); }
    function closeModalGerarMarePrevista() { setModalGerarMarePrevistaIsOpen(false); }

    const [modalStatisticsIsOpen, setModalStatisticsIsOpen] = useState(false);
    function openModalStatistics() { setModalStatisticsIsOpen(true); }
    function closeModalStatistics() { setModalStatisticsIsOpen(false); }

    /********** Constantes **********/
    const CORRECT_POINT = "blue";
    const INCORRECT_POINT = "orange";
    const MAREGRAMA_COLOR = "blue";
    const MARE_PREVISTA_COLOR = "green";
    const DISCREPANCIA_COLOR = "red";
    const TAMANHO_PAGINA = 500;

    /********** useState **********/
    const [selectedPoint, setSelectedPoint] = useState({});
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [tickCount, setTickCount] = useState(5);
    const [editValue, setEditValue] = useState(0);
    const [pointsToBeDeleted, setPointsToBeDeleted] = useState([]);
    const [stepMaregrafico, setStepMaregrafico] = useState(0);
    const [stepMarePrevista, setStepMarePrevista] = useState(0);
    const [toggleValueMaregrama, setToggleValueMaregrama] = useState(true);
    const [toggleValueMarePrevista, setToggleValueMarePrevista] = useState(true);
    const [toggleDiscrepancia, setToggleDiscrepancia] = useState(true);
    const [maxValueGraphic, setMaxValueGraphic] = useState(null);
    const [minValueGraphic, setMinValueGraphic] = useState(null);
    const [visibleData, setVisibleData] = useState([]);
    const [visibleDataMarePrevista, setVisibleDataMarePrevista] = useState([]);
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [totalPaginas, setTotalPaginas] = useState(1);

    const [responseData, setResponseData] = useState(null); // Armazena os dados retornados pela API (Estatísticas)

    const [isMountedDataset, setIsMountedDataset] = useState(false);
    const [isMountedDatasetMarePrevista, setIsMountedDatasetMarePrevista] = useState(false);

    /********** useContext **********/
    const {
        dataSet,
        dataSetMarePrevista,
        setDataSet,
        setDataSetMarePrevista,
        text,
        textMarePrevista,
        setText,
        setTextMarePrevista,
        generateGraph,
        generateGraphMarePrevista,
        setGenerateGraph,
        setGenerateGraphMarePrevista,
        headerErase,
        headerEraseMarePrevista,
        setHeaderErase,
        setHeaderEraseMarePrevista,
        columns,
        columnsMarePrevista,
        setColumns,
        setColumnsMarePrevista,
        separators,
        separatorsMarePrevista,
        setSeparators,
        setSeparatorsMarePrevista,
        options,
        setHeaderLines,
        setHeaderLinesMarePrevista,
        nextComponent,
        numColumn,
        numColumnMarePrevista,
        setNumColumn,
        setNumColumnMarePrevista,
        firstIteration,
        setFirstIteration,
        extraData,
        setExtraData
    } = useContext(SharedStateContext);

    /********** Funções **********/
    const irParaPagina = (novaPagina) => {
        setPaginaAtual(Math.max(0, Math.min(novaPagina, totalPaginas - 1)));
    };

    // Adiciona pontos na lista para serem apagados
    const addPointDelete = (index) => {
        setPointsToBeDeleted([...pointsToBeDeleted, index]);
    };

    // Remove pontos da lista para serem apagados
    const removePointDelete = (index) => {
        setPointsToBeDeleted(pointsToBeDeleted.filter((item) => item !== index));
    };

    // Apaga os pontos da lista
    const applyPointsDelete = () => {
        setDataSet(
            dataSet.filter((item) => !pointsToBeDeleted.includes(item.index))
        );
        setPointsToBeDeleted([]);
    };

    const domainChange = (domain) => {
        //verifica a largura do domínio do eixo x
        const [xMin, xMax] = domain.x;
        const xWidth = Math.abs(xMax - xMin);

        // Atualiza o número de ticks do eixo x com base na largura do domínio
        if (xWidth < 2) {
            setTickCount(5);
        } else if (xWidth < 5) {
            setTickCount(10);
        } else {
            setTickCount(20);
        }
    };

    const changePointValue = (event) => {
        const newValue = event.target.value;

        if (newValue === "") {
            setEditValue("");
            return;
        }

        const parsedValue = parseFloat(newValue);

        if (!isNaN(parsedValue) && selectedPoint?.index) {
            setEditValue(parsedValue);
            dataSet[selectedPoint.index].value = parsedValue;
            setDataSet([...dataSet]);
        }
    }

    const nextStepMaregrafico = () => {
        if (stepMaregrafico === 0) {
            setStepMaregrafico(1)
        }
        else if (stepMaregrafico === 1) {
            setStepMaregrafico(0);
        }
    }

    const nextStepMarePrevista = () => {
        if (stepMarePrevista === 0) {
            setStepMarePrevista(1)
        }
        else if (stepMarePrevista === 1) {
            setStepMarePrevista(0);
        }
    }

    //Função para calcular a discrepancia entre os gráficos
    const calculateDiscrepancy = (data1, data2) => {
        if (!data1 || !data2 || data1.length === 0 || data2.length === 0) {
            return [];
        }

        // Garante que ambos os conjuntos de dados tenham o mesmo número de pontos.
        if (data1.length !== data2.length) {
            console.error("Os conjuntos de dados não têm o mesmo número de pontos.");
            return [];
        }

        // Calcula a discrepância ponto a ponto
        const discrepancies = data1.map((point1, index) => {
            const point2 = data2[index];
            if (point1.index !== point2.index) {
                console.warn(`Índices diferentes detectados: ${point1.index} vs ${point2.index}`);
            }
            return point1.value - point2.value;
        });

        return discrepancies;
    };

    /**********  useMemo  **********/
    const derivedDataSetMarePrevista = useMemo(() => {
        let aux = [];
        let dataSetMarePrevistaMap = new Map();

        // Criar o mapa utilizando a data no formato ISO como chave
        if (dataSetMarePrevista?.length > 0) {
            dataSetMarePrevista.forEach(item => {
                dataSetMarePrevistaMap.set(new Date(item.date).toISOString(), item);
            });
        }

        if (dataSet?.length > 0) {
            dataSet.forEach(item => {
                // Comparar utilizando o formato ISO
                const matchingItem = dataSetMarePrevistaMap.get(new Date(item.date).toISOString());
                if (matchingItem) {
                    aux.push(matchingItem);
                } else {
                    aux.push({
                        index: item.index,
                        date: item.date,
                        value: item.value,
                        authentic: false
                    });
                }
            });
        }

        return aux;
    }, [dataSet, dataSetMarePrevista]);

    /**********  useEffect  **********/
    useEffect(() => {
        if (isMountedDataset) {
            const { headerLines, dataLines } = separateBodyFromHeader(
                columns,
                separators,
                text,
                headerErase
            );

            setHeaderLines(headerLines);

            mountDataset(
                separators,
                options,
                dataLines,
                columns,
                setDataSet,
                maxValueGraphic,
                minValueGraphic,
                setMaxValueGraphic,
                setMinValueGraphic,
            );
        }
        else {
            // Marca o componente como montado após o primeiro render
            setIsMountedDataset(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateGraph]);

    useEffect(() => {
        if (isMountedDatasetMarePrevista) {
            const { headerLines, dataLines } = separateBodyFromHeader(
                columnsMarePrevista,
                separatorsMarePrevista,
                textMarePrevista,
                headerEraseMarePrevista
            );

            setHeaderLinesMarePrevista(headerLines);

            mountDataset(
                separatorsMarePrevista,
                options,
                dataLines,
                columnsMarePrevista,
                setDataSetMarePrevista,
                maxValueGraphic,
                minValueGraphic,
                setMaxValueGraphic,
                setMinValueGraphic,
            );
        }
        else {
            setIsMountedDatasetMarePrevista(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateGraphMarePrevista]);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        // Recalcula o número de páginas
        const novoTotalPaginas = Math.ceil(dataSet?.length / TAMANHO_PAGINA);
        setTotalPaginas(novoTotalPaginas);

        // Ajusta a página atual se necessário
        if (paginaAtual >= novoTotalPaginas) {
            setPaginaAtual(0); // Volta para a primeira página
        }
        else {
            // Atualiza os dados visíveis diretamente
            const inicio = paginaAtual * TAMANHO_PAGINA;
            const fim = inicio + TAMANHO_PAGINA;

            //Só realizar esses sets quando a mudança em dataSet e derivedDataSetMarePrevist for causado pela alteração do tamanho dos dados
            setVisibleData(dataSet?.slice(inicio, fim));
            setVisibleDataMarePrevista(derivedDataSetMarePrevista?.slice(inicio, fim));
        }

    }, [dataSet, derivedDataSetMarePrevista, TAMANHO_PAGINA, paginaAtual]);

    useEffect(() => {

        const fetchData = async () => {
            try {
                // Faz uma requisição POST para o endpoint da API
                const response = await axios.post("https://tide-editor-api.gphidro.com.br/statistics", { data: dataSet });

                setResponseData(response.data); // Armazena os dados da resposta

                if (firstIteration) {
                    setExtraData(response.data); // Salva em outro estado na primeira iteração
                    setFirstIteration(false); // Define como false após a primeira iteração
                }
            } catch (error) {
                console.error("Erro ao consultar a rota:", error);
            }
        };

        if (dataSet.length > 0) {
            fetchData(); // Chama a função que faz a requisição
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSet, firstIteration]); // Dependências incluem `firstIteration`

    const discrepancies = calculateDiscrepancy(visibleData, visibleDataMarePrevista);

    return (
        <div className={`${styles.container} ${styles.main_app}`}>
            <div className={styles.menu_graphic}>
                <button onClick={openModalGerarMaregrafico}>
                    Gerar Maregrama
                </button>
                <Modal
                    isOpen={modalGerarMaregraficoIsOpen}
                    onRequestClose={closeModalGerarMaregrafico}
                    contentLabel="Example Modal"
                >
                    <div className={styles.div_modal}>
                        <div className={styles.div_button_modal}>
                            <button
                                className={styles.button_modal}
                                onClick={nextStepMaregrafico}
                            >
                                {stepMaregrafico === 0 ? "Próximo" : "Voltar"}
                            </button>

                            <button onClick={closeModalGerarMaregrafico}>X</button>
                        </div>
                        {
                            stepMaregrafico === 0 &&
                            <TextEditor
                                text={text}
                                setText={setText}
                                nextStep={nextComponent}
                            />
                        }
                        {
                            stepMaregrafico === 1 &&
                            <ColumnDefinition
                                text={text}
                                setText={setText}
                                generateGraph={generateGraph}
                                setGenerateGraph={setGenerateGraph}
                                headerErase={headerErase}
                                setHeaderErase={setHeaderErase}
                                columns={columns}
                                numColumn={numColumn}
                                setNumColumn={setNumColumn}
                                setColumns={setColumns}
                                separators={separators}
                                setSeparators={setSeparators}
                                closeModal={closeModalGerarMaregrafico}
                            />
                        }


                    </div>
                </Modal>
            </div>

            <div className={styles.header_graphic}>
                <div className={styles.subheader_graphic}>
                    <label className={styles.label}>
                        <Tippy
                            content="Id do último ponto selecionado"
                            placement="right"
                            offset={[0, 10]}     // Desloca o tooltip horizontalmente (para a direita)
                            className={styles.tooltip} // Aplica a classe CSS personalizada
                        >
                            <span>Ponto:</span>
                        </Tippy>

                        <input
                            className={styles.input}
                            type="number"
                            value={selectedPoint?.index ? selectedPoint.index : 0}
                            readOnly
                        />
                    </label>
                    <label className={styles.label}>
                        <Tippy
                            content="Data do último ponto selecionado"
                            placement="right"
                            offset={[0, 10]}     // Desloca o tooltip horizontalmente (para a direita)
                            className={styles.tooltip} // Aplica a classe CSS personalizada
                        >
                            <span>Data:</span>
                        </Tippy>
                        <input
                            className={styles.input}
                            type="text"
                            value={selectedPoint?.date ? selectedPoint.date : ""}
                            readOnly
                        />
                    </label>
                    <label className={styles.label}>
                        <Tippy
                            content="Leitura do último ponto selecionado"
                            placement="right"
                            offset={[0, 10]}     // Desloca o tooltip horizontalmente (para a direita)
                            className={styles.tooltip} // Aplica a classe CSS personalizada
                        >
                            <span>Valor:</span>
                        </Tippy>

                        <input
                            className={styles.input}
                            type="number"
                            value={editValue ? editValue : 0}
                            onChange={(event) => changePointValue(event)}
                        />
                    </label>
                </div>
                <div className={styles.subheader_graphic}>
                    <button onClick={openModalStatistics}>
                        Estatísticas
                    </button>
                    <StatisticsModal
                        openModalStatistics={modalStatisticsIsOpen}
                        closeModalStatistics={closeModalStatistics}
                        rawStatistics={extraData}
                        editedStatistics={responseData}
                    />
                    <div className={styles.ferbar}>
                        <Tippy
                            content="Apagar ponto selecionado"
                            placement="bottom"
                            offset={[0, 10]} // Ajuste do deslocamento
                            className={styles.tooltip} // Classe CSS personalizada
                        >
                            <button className={styles.button_ferbar} onClick={applyPointsDelete}>
                                <img
                                    className={styles.img_ferbar}
                                    src={IconeBorracha}
                                    alt="Ícone Borracha"
                                />
                            </button>
                        </Tippy>
                        <Tippy
                            content="Download do arquivo de entrada do PacMare"
                            placement="bottom"
                            offset={[0, 10]} // Ajuste do deslocamento
                            className={styles.tooltip} // Classe CSS personalizada
                        >
                            <div>
                                <DownloadButton />
                            </div>
                        </Tippy>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <VictoryChart
                        theme={VictoryTheme.material}
                        width={windowSize.width}
                        height={windowSize.height - 450}
                        containerComponent={<VictoryZoomContainer responsive={true} zoomDimension="x" />}
                        onDomainChange={domainChange}
                        domainPadding={{ x: 10, y: 10 }}
                    >
                        {
                            toggleValueMarePrevista &&
                            <VictoryLine
                                data={visibleDataMarePrevista.map((item) => ({
                                    ...item,
                                    value: item.authentic ? item.value : null, // Remova o ponto negativo
                                }))}
                                x="date"
                                y="value"
                                style={{ data: { stroke: MARE_PREVISTA_COLOR, strokeWidth: 2, } }}
                                domain={{ y: [minValueGraphic ?? 0, maxValueGraphic ?? 100] }}
                            />
                        }

                        {
                            toggleValueMaregrama &&
                            <VictoryLine
                                data={visibleData}
                                x="date"
                                y="value"
                                style={{ data: { stroke: MAREGRAMA_COLOR, strokeWidth: 2 } }}
                            />
                        }

                        {toggleDiscrepancia && discrepancies.length > 0 && (
                            <VictoryLine
                                data={discrepancies.map((value, index) => ({
                                    x: visibleData[index].date,
                                    y: value,
                                }))}
                                style={{ data: { stroke: DISCREPANCIA_COLOR } }}
                            />
                        )}

                        <VictoryAxis
                            dependentAxis
                            domain={[minValueGraphic ?? 0, maxValueGraphic ?? 100]}
                        />

                        <VictoryAxis
                            independentAxis
                            tickCount={tickCount}
                            style={{
                                axis: { stroke: 'black' },
                                tickLabels: { fontSize: 10 },
                            }}

                            tickFormat={(t) => {
                                let dateObject = moment.utc(t);
                                return dateObject.format("DD/MM/YYYY HH:mm:ss").toString();
                            }}
                        />

                        {
                            toggleValueMaregrama &&
                            <VictoryScatter
                                data={visibleData}
                                x="date"
                                y="value"
                                size={4}
                                style={{
                                    data: { fill: CORRECT_POINT, stroke: 'white', strokeWidth: 2 },
                                }}
                                eventKey={(event) => `${event.index},${event.value}`}
                                events={[
                                    {
                                        target: 'data',
                                        eventHandlers: {
                                            onClick: () => [
                                                {
                                                    target: 'data',
                                                    mutation: (p) => {
                                                        const fill = p.style && p.style.fill;
                                                        const point = p.data[p.index];

                                                        let dateObject = moment.utc(point?.date);
                                                        const dateForm = dateObject.format("DD/MM/YYYY HH:mm:ss");

                                                        const value = {
                                                            index: point.index,
                                                            date: dateForm,
                                                            value: point.value
                                                        }

                                                        setSelectedPoint(value);
                                                        setEditValue(value.value);


                                                        if (fill === CORRECT_POINT) {
                                                            addPointDelete(value.index);
                                                        } else if (fill === INCORRECT_POINT) {
                                                            removePointDelete(value.index);
                                                        }

                                                        return fill === CORRECT_POINT
                                                            ? { style: { fill: INCORRECT_POINT } }
                                                            : { style: { fill: CORRECT_POINT } };
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                ]}
                            />
                        }

                    </VictoryChart>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '50px', justifyContent: 'center', padding: '10px' }}>
                        <ToggleSwitch
                            isOn={toggleValueMaregrama}
                            onToggle={setToggleValueMaregrama}
                            label="Maregrama"
                            colorToggle={MAREGRAMA_COLOR}
                        />

                        <ToggleSwitch
                            isOn={toggleValueMarePrevista}
                            onToggle={setToggleValueMarePrevista}
                            label="Maré Prevista"
                            colorToggle={MARE_PREVISTA_COLOR}
                        />

                        <ToggleSwitch
                            isOn={toggleDiscrepancia}
                            onToggle={setToggleDiscrepancia}
                            label="Discrepância"
                            colorToggle={DISCREPANCIA_COLOR}
                        />

                    </div>
                </div>
                <div>
                    {/* Controles de Paginação */}
                    <div style={{ marginTop: "10px", textAlign: "center", display: "flex", justifyContent: "center", gap: "10px" }}>
                        <button onClick={() => irParaPagina(0)} disabled={paginaAtual === 0}>
                            {"<<"} Primeira
                        </button>
                        <button onClick={() => irParaPagina(paginaAtual - 1)} disabled={paginaAtual === 0}>
                            {"<"} Anterior
                        </button>
                        <span style={{ margin: "0 10px" }}>
                            Página {paginaAtual + 1} de {totalPaginas}
                        </span>
                        <button
                            onClick={() => irParaPagina(paginaAtual + 1)}
                            disabled={paginaAtual === totalPaginas - 1}
                        >
                            Próxima {">"}
                        </button>
                        <button
                            onClick={() => irParaPagina(totalPaginas - 1)}
                            disabled={paginaAtual === totalPaginas - 1}
                        >
                            Última {">>"}
                        </button>
                    </div>
                </div>
            </div>

            <button onClick={openModalGerarMarePrevista}>
                Gerar Gráfico de Maré Prevista
            </button>
            <Modal
                isOpen={modalGerarMarePrevistaIsOpen}
                onRequestClose={closeModalGerarMarePrevista}
                contentLabel="Example Modal"
            >
                <div className={styles.div_modal}>
                    <div className={styles.div_button_modal}>
                        <button
                            className={styles.button_modal}
                            onClick={nextStepMarePrevista}
                        >
                            {stepMarePrevista === 0 ? "Próximo" : "Voltar"}
                        </button>

                        <button onClick={closeModalGerarMarePrevista}>X</button>
                    </div>

                    {
                        stepMarePrevista === 0 &&
                        <TextEditor
                            text={textMarePrevista}
                            setText={setTextMarePrevista}
                            nextStep={nextComponent}
                        />
                    }
                    {
                        stepMarePrevista === 1 &&
                        <ColumnDefinition
                            text={textMarePrevista}
                            setText={setTextMarePrevista}
                            generateGraph={generateGraphMarePrevista}
                            setGenerateGraph={setGenerateGraphMarePrevista}
                            headerErase={headerEraseMarePrevista}
                            setHeaderErase={setHeaderEraseMarePrevista}
                            columns={columnsMarePrevista}
                            numColumn={numColumnMarePrevista}
                            setNumColumn={setNumColumnMarePrevista}
                            setColumns={setColumnsMarePrevista}
                            separators={separatorsMarePrevista}
                            setSeparators={setSeparatorsMarePrevista}
                            closeModal={closeModalGerarMarePrevista}
                        />
                    }
                </div>
            </Modal>
        </div>
    )
}

export default Graphic;