import React from "react";
import styles from "../styles/sharedStyles.module.css";

const ToggleSwitch = ({ isOn, onToggle, label, colorToggle }) => {
    const handleToggle = () => {
      onToggle(!isOn); // Chama a função passada pelo pai para alterar o estado
    };

 // Define a cor do botão com base no estado (ligado/desligado)
 const toggleBackgroundColor = isOn ? colorToggle : "#ccc"; // Cinza quando desligado
  
 return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/* Label em frente ao botão */}
      <span className={styles.label_estilo_toggleSwitch}  style={{ color: colorToggle }} >{label}</span>
        <label className={styles.switch}>
            <input type="checkbox" checked={isOn} onChange={handleToggle} />
            <span className={styles.slider} style={{ backgroundColor: toggleBackgroundColor}} ></span>
        </label>
    </div>
  );
};

export default ToggleSwitch;