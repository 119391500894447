import React, { useContext } from "react";

import styles from "../styles/sharedStyles.module.css";

import FileImg from "../source/file.svg";
import SineImg from "../source/sine.svg";
import CalculatorImg from "../source/calculator.svg";
import InfoImg from "../source/info.svg";

import { SharedStateContext } from "../contexts/SharedStateContext";

function NavBar() {

    const { step, setStep } = useContext(SharedStateContext);

    // Função para determinar se o elemento deve ser destacado
    const isElevated = (elem) => {
        if (elem === "FileImg" && step === 0) {
            return true;
        }
        else if (elem === "SineImg" && step === 1) {
            return true;
        }
        else if (elem === "CalculatorImg" && step === 2) {
            return true;
        }
        else if (elem === "DownloadsImg" && step === 3) {
            return true;
        }
        else if (elem === "InfoImg" && step === 4) {
            return true;
        }
};

return (
    <nav className={`${styles.container}, ${styles.navbar_app}`}>
        <ul className={styles.ul_navbar}>
            <li className={`${styles.li_navbar} ${isElevated("FileImg") ? styles["elevated"] : ""}`}>
                <button
                    className={styles.button_navbar}
                    onClick={() => setStep(0)}
                >
                    <img
                        className={styles.img_navbar}
                        src={FileImg}
                        alt="FileImg"
                    />
                </button>
            </li>
            <li className={`${styles.li_navbar} ${isElevated("SineImg") ? styles["elevated"] : ""}`}>
                <button
                    className={styles.button_navbar}
                    onClick={() => setStep(1)}
                >
                    <img
                        className={styles.img_navbar}
                        src={SineImg}
                        alt="SineImg"
                    />
                </button>
            </li>
            <li className={`${styles.li_navbar} ${isElevated("CalculatorImg") ? styles["elevated"] : ""}`}>
                <button
                    className={styles.button_navbar}
                    onClick={() => setStep(2)}
                >
                    <img
                        className={styles.img_navbar}
                        src={CalculatorImg}
                        alt="CalculatorImg"
                    />
                </button>
            </li>
            <li className={`${styles.li_navbar} ${isElevated("InfoImg") ? styles["elevated"] : ""} ${styles.info_button}`}>
                <button
                    className={styles.button_navbar}
                    onClick={() => setStep(3)}
                >
                    <img
                        className={styles.img_navbar}
                        src={InfoImg}
                        alt="InfoImg"
                    />
                </button>
            </li>
        </ul>
    </nav>
)
}

export default NavBar;