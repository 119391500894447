import React, { useContext } from "react";
import styles from "../styles/sharedStyles.module.css";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // Importa o CSS do Tippy.js
import { SharedStateContext } from "../contexts/SharedStateContext";
import StationMap from "./stationMap.js"; // Importa o componente do mapa

function StationInformation() {
    const { options, setOptions, nextComponent } = useContext(SharedStateContext);

    function handleChange(event) {
        const { name, value } = event.target;

        if (name === 'fuso') {
            if (value === '' || (value >= -12 && value <= 12)) {
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    [name]: value,
                }));
            }
        } else {
            setOptions((prevOptions) => ({
                ...prevOptions,
                [name]: value,
            }));
        }
    };

    const handleKeyDown = (event) => {
        const value = event.target.value;
        const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Tab"];

        if (
            !/^[0-9]$/.test(event.key) &&
            !allowedKeys.includes(event.key) &&
            !(event.key === "-" && value === "")
        ) {
            event.preventDefault();
        }
    };

    // Objeto contendo os valores da latitude (grau, minuto, segundo, direção)
    const latitude = {
        grau: parseInt(options.latitude_grau || 0, 10), // Grau em número inteiro
        min: parseInt(options.latitude_min || 0, 10), // Minuto em número inteiro
        seg: parseFloat(options.latitude_seg || 0), // Segundo em número decimal
        direcao: options.latitude || "N", // Direção (Norte ou Sul)
    };

    // Objeto contendo os valores da longitude (grau, minuto, segundo, direção)
    const longitude = {
        grau: parseInt(options.longitude_grau || 0, 10), // Grau em número inteiro
        min: parseInt(options.longitude_min || 0, 10), // Minuto em número inteiro
        seg: parseFloat(options.longitude_seg || 0), // Segundo em número decimal
        direcao: options.longitude || "E", // Direção (Leste ou Oeste)
    };


    return (
        <div className={`${styles.container} ${styles.main_app}`}>
            <div className={styles.form}>

                {/* Nome da Estação */}
                <label className={styles.label}>
                    <Tippy
                        content="Insira o nome da estação."
                        placement="right"
                        offset={[0, 10]}     // Desloca o tooltip horizontalmente (para a direita)
                        className={styles.tooltip} // Aplica a classe CSS personalizada
                    >
                        <span>Nome da Estação:</span>
                    </Tippy>
                    <input
                        className={styles.name}
                        name="nome_estacao"
                        value={options.nome_estacao}
                        required
                        type="text"
                        onChange={(handleChange)}
                    />
                </label>

                {/* Latitude */}
                <label className={styles.label}>
                    <Tippy
                        content="Insira a latitude da estação."
                        placement="right"
                        offset={[0, 10]}     // Desloca o tooltip horizontalmente (para a direita)
                        className={styles.tooltip} // Aplica a classe CSS personalizada
                    >
                        <span>Latitude:</span>
                    </Tippy>

                    <div>
                        <input
                            name="latitude_grau"
                            value={options.latitude_grau}
                            className={styles.input}
                            required
                            type="number"
                            step="1"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />{" º"}

                        <input
                            name="latitude_min"
                            value={options.latitude_min}
                            className={styles.input}
                            required
                            type="number"
                            step="1"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />{" '"}

                        <input
                            name="latitude_seg"
                            value={options.latitude_seg}
                            className={styles.input}
                            required
                            type="number"
                            onChange={handleChange}
                        />{' "'}

                        <select
                            name="latitude"
                            value={options.latitude}
                            className={styles.select}
                            required
                            onChange={handleChange}
                        >
                            <option value="N">N</option>
                            <option value="S">S</option>
                        </select>
                    </div>
                </label>

                {/* Longitude */}
                <label className={styles.label}>
                    <Tippy
                        content="Insira a longitude da estação."
                        placement="right"
                        offset={[0, 10]}     // Desloca o tooltip horizontalmente (para a direita)
                        className={styles.tooltip} // Aplica a classe CSS personalizada
                    >
                        <span>Longitude:</span>
                    </Tippy>

                    <div>
                        <input
                            name="longitude_grau"
                            value={options.longitude_grau}
                            className={styles.input}
                            required
                            type="number"
                            step="1"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />{" º"}

                        <input
                            name="longitude_min"
                            value={options.longitude_min}
                            className={styles.input}
                            required
                            type="number"
                            step="1"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />{" '"}

                        <input
                            name="longitude_seg"
                            value={options.longitude_seg}
                            className={styles.input}
                            required
                            type="number"
                            onChange={handleChange}
                        />{' "'}

                        <select
                            name="longitude"
                            value={options.longitude}
                            className={styles.select}
                            required
                            onChange={handleChange}
                        >
                            <option value="E">E</option>
                            <option value="W">W</option>
                        </select>
                    </div>
                </label>

                {/* Fuso */}
                <label className={styles.label}>
                    <Tippy
                        content="Insira o fuso da estação, que será negativo se a estação estiver localizada a oeste de Greenwich."
                        placement="right"
                        offset={[0, 10]}     // Desloca o tooltip horizontalmente (para a direita)
                        className={styles.tooltip} // Aplica a classe CSS personalizada
                    >
                        <span>Fuso:</span>
                    </Tippy>

                    <input
                        name="fuso"
                        value={options.fuso}
                        className={styles.input}
                        required
                        type="number"
                        step="1"
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                    />
                </label>

                {/* Hora inicial */}
                <label className={styles.label}>
                    <Tippy
                        content="Insira a hora de início da coleta dos dados maregráficos."
                        placement="right"
                        offset={[0, 10]}     // Desloca o tooltip horizontalmente (para a direita)
                        className={styles.tooltip} // Aplica a classe CSS personalizada
                    >
                        <span>Hora Inicial:</span>
                    </Tippy>

                    <input
                        name="hora_inicial"
                        value={options.hora_inicial}
                        className={styles.input}
                        required
                        type="time"
                        step="1"
                        onChange={handleChange}
                    />
                </label>

                {/* Data inicial */}
                <label className={styles.label}>
                    <Tippy
                        content="Insira a data de início da coleta dos dados maregráficos."
                        placement="right"
                        offset={[0, 10]}     // Desloca o tooltip horizontalmente (para a direita)
                        className={styles.tooltip} // Aplica a classe CSS personalizada
                    >
                        <span>Data Inicial:</span>
                    </Tippy>

                    <input
                        name="data_inicial"
                        value={options.data_inicial}
                        className={styles.input}
                        type="date"
                        required
                        onChange={handleChange}
                    />
                </label>

                {/* Intervalo de tempo entre as coletas da altura de maré: */}
                <label className={styles.label}>
                    <Tippy
                        content="Insira o intervalo de tempo entre as coletas da altura de maré."
                        placement="right"
                        offset={[0, 10]}     // Desloca o tooltip horizontalmente (para a direita)
                        className={styles.tooltip} // Aplica a classe CSS personalizada
                    >
                        <span>Intervalo de tempo entre as coletas da altura de maré:</span>
                    </Tippy>

                    <input
                        name="intervalo"
                        value={options.intervalo}
                        className={styles.input}
                        required
                        type="time"
                        step="1"
                        onChange={handleChange}
                    />
                </label>

                {/* Fator de escala */}
                <label className={styles.label}>
                    <Tippy
                        content="Informe se a coleta das alturas de maré está em centímetros ou em metros."
                        placement="right"
                        offset={[0, 10]}     // Desloca o tooltip horizontalmente (para a direita)
                        className={styles.tooltip} // Aplica a classe CSS personalizada
                    >
                        <span>Fator de Escala:</span>
                    </Tippy>

                    <select
                        name="fator_escala"
                        value={options.fator_escala}
                        className={styles.select}
                        required
                        onChange={handleChange}
                    >
                        <option value="1">centímetros</option>
                        <option value="100">metros</option>
                    </select>
                </label>
                <br/> 
                {/* Renderiza o mapa abaixo do formulário */}
                <h2 className={styles.h2_mapa}>Mapa de Localização</h2>
                <StationMap
                    latitude={latitude} // Passa os dados da latitude
                    longitude={longitude} // Passa os dados da longitude
                    nomeEstacao={options.nome_estacao} // Passa o nome da estação
                />

                <button type="submit" className={styles.button} onClick={() => nextComponent()}>Continuar</button>
            </div>
        </div>
    )
}

export default StationInformation;