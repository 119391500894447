import React, { useEffect, useContext } from "react";
import axios from "axios";
import styles from "../styles/sharedStyles.module.css";

import ConstantFile from "./constantFile";

import { SharedStateContext } from '../contexts/SharedStateContext'

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // Importa o CSS do Tippy.js

function Calculator() {

    /********** useContext **********/
    const {
        bodyCalculator,
        responseDataCalculator,
        setResponseDataCalculator,
        calcCalculator,
        setCalcCalculator,
        middleLevelCalculator,
        setMiddleLevelCalculator,
        omegaM2Calculator,
        setOmegaM2Calculator
    } = useContext(SharedStateContext);

    // Atualiza o estado do NMML (nível médio do mar local) quando o usuário digita no campo correspondente
    const changeMiddleLevel = (event) => {
        const value = event.target.value;
        setMiddleLevelCalculator(parseFloat(value));
    }

    // Atualiza o estado de ωM2 (frequência angular) quando o usuário digita no campo correspondente
    const changeOmegaM2 = (event) => {
        const value = event.target.value;
        setOmegaM2Calculator(parseFloat(value));
    };

    //Função para formatar para o usuário valores com 4 casas decimais
    const formatNumber = (value, precision = 5) => {
        return parseFloat(value.toFixed(precision));
    };

    // useEffect que realiza a chamada à API sempre que o estado "calcCalculator" mudar
    useEffect(() => {
        if (!calcCalculator) return; // Evita chamadas desnecessárias à API

        const fetchData = async () => {
            // Atualiza o corpo da requisição com NMML e ωM2 fornecidos pelo usuário
            const updatedBody = { ...bodyCalculator, NMML: middleLevelCalculator, omegaM2: omegaM2Calculator };

            try {
                // Faz uma requisição POST para o endpoint da API
                const response = await axios.post("https://tide-editor-api.gphidro.com.br/nr", updatedBody);
                setResponseDataCalculator(response.data); // Armazena os dados da resposta
            } catch (error) {
                console.error("Erro ao consultar a rota:", error);
            }
        };

        fetchData(); // Chama a função que faz a requisição

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calcCalculator, bodyCalculator, middleLevelCalculator, omegaM2Calculator]);// Dependências do useEffect

    return (
        <div className={`${styles.container} ${styles.main_app}`}>
            {/* Formulário para entrada de dados */}
            <div className={styles.form}>

                {/* NMML */}
                <label className={styles.label}>
                    <Tippy
                        content="Insira o valor do NMML em cm"
                        placement="right"
                        offset={[0, 10]}     // Desloca o tooltip horizontalmente (para a direita)
                        className={styles.tooltip} // Aplica a classe CSS personalizada
                    >
                        <span>Nível Médio do Mar Local (NMML):</span>
                    </Tippy>
                    <input
                        name="nivel"
                        type="number"
                        className={styles.input}
                        value={middleLevelCalculator}
                        onChange={(event) => changeMiddleLevel(event)} // Atualiza o estado do NMML
                    />
                </label>

                {/* Campo para o ωM2 */}
                <label className={styles.label}>
                    <Tippy
                        content="Insira o valor de ωM2 (frequência angular em graus/hora)"
                        placement="right"
                        offset={[0.0001, 10]}
                        className={styles.tooltip}
                    >
                        <span>Frequência Angular da Constante M2 (ωM2):</span>
                    </Tippy>
                    <input
                        name="omega"
                        type="number"
                        step="0.0001"
                        className={styles.input}
                        value={omegaM2Calculator}
                        onChange={(event) => changeOmegaM2(event)} // Atualiza o estado de ωM2
                    />
                </label>

                {/* Arquivo CH */}
                <label className={styles.label}>
                    <Tippy
                        content="Insira o arquivo das constantes harmônicas"
                        placement="right"
                        offset={[0, 10]}     // Desloca o tooltip horizontalmente (para a direita)
                        className={styles.tooltip} // Aplica a classe CSS personalizada
                    >
                        <span>Arquivo das Constantes Harmônicas:</span>
                    </Tippy>

                    <ConstantFile /> {/* Atualiza o estado `body` com os dados processados */}
                </label>

                {/* Botão para iniciar o cálculo */}
                <button onClick={() => { setCalcCalculator(!calcCalculator) }}>Calcular</button>
            </div>

            {/* Exibição dos resultados */}
            <div className={styles.results}>
                <br></br>
                <p className={styles.li}>Resultados:</p>
                {/* {error && <p>{error}</p>} */}
                {responseDataCalculator ? (
                    <div className={styles['results-box']}>
                        {
                            responseDataCalculator?.C &&
                            responseDataCalculator?.resultado?.classificacaoCriterioCourtier &&
                            responseDataCalculator?.resultado?.Zo &&
                            (
                                <div>
                                    <h3 className={styles.h3_calculos}>Critério de Courtier:</h3>
                                    <ul className={styles.ul}>
                                        <li className={styles.li}>C = {formatNumber(responseDataCalculator?.C)}</li>
                                        <li className={styles.li}>Classificação Maregráfica = {responseDataCalculator?.resultado?.classificacaoCriterioCourtier} </li>
                                        {
                                            !(responseDataCalculator.C > 0.25 && responseDataCalculator.C <= 1.5) &&
                                            (<li className={styles.li}>Z0 = {formatNumber(responseDataCalculator?.resultado?.Zo)} cm</li>)
                                        }       
                                    </ul>
                                </div>
                            )
                        }
                        {
                            responseDataCalculator?.resultado?.["2K"] &&
                            responseDataCalculator?.resultado?.classificacaoBALAY &&
                            responseDataCalculator?.resultado?.Zo &&
                            (
                                <div>
                                    <h3 className={styles.h3_calculos}>Método de Balay:</h3>
                                    <ul className={styles.ul}>
                                        <li className={styles.li}>2K = {formatNumber(responseDataCalculator?.resultado?.["2K"])}</li>
                                        <li className={styles.li}>Classificação Maregráfica = {responseDataCalculator?.resultado?.classificacaoBALAY}</li>
                                        {
                                            !(responseDataCalculator.C <= 0.25 && responseDataCalculator.C > 1.5) &&
                                            (<li className={styles.li}>Z0 = {formatNumber(responseDataCalculator?.resultado?.Zo)} cm</li>)
                                        }
                                    </ul>
                                </div>   
                            )
                        }
                        {
                            responseDataCalculator?.NR &&
                            (
                                <div>
                                    <h3 className={styles.h3_calculos}>Nível de Redução seguro:</h3>
                                    <ul className={styles.ul}>
                                        <li className={styles.li}>NR = {formatNumber(responseDataCalculator?.NR)} cm</li>
                                    </ul>
                                </div>     
                            )
                        }
                        {
                            responseDataCalculator?.elementosMaregraficos &&
                            (
                                <div>
                                    <h3 className={styles.h3_calculos}>Elementos Maregráficos:</h3>
                                    <ul className={styles.ul}>
                                        <li className={styles.li}>HWF&C = {responseDataCalculator?.elementosMaregraficos?.["HWF&C"]} h</li>
                                        <li className={styles.li}>MHWS = {formatNumber(responseDataCalculator?.elementosMaregraficos?.MHWS)} cm</li>
                                        <li className={styles.li}>MLWS = {formatNumber(responseDataCalculator?.elementosMaregraficos?.MLWS)} cm</li>
                                        <li className={styles.li}>MHWN = {formatNumber(responseDataCalculator?.elementosMaregraficos?.MHWN)} cm</li>
                                        <li className={styles.li}>MLWN = {formatNumber(responseDataCalculator?.elementosMaregraficos?.MLWN)} cm</li>
                                        <br></br>
                                    </ul>
                                    </div>
                            )
                        }
                    </div>
                ) : (
                    <p>...</p>
                )}
            </div>
        </div>
    );
}

export default Calculator;
