import React from "react";
import { MapContainer, TileLayer, Circle, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import styles from "../styles/sharedStyles.module.css";

// Função para converter coordenadas em graus, minutos e segundos (DMS) para o formato decimal
const dmsToDecimal = (degrees, minutes, seconds, direction) => {
  let decimal = Math.abs(degrees) + minutes / 60 + seconds / 3600;
  if (direction === "S" || direction === "W") {
    // Converte para negativo se a direção for Sul (S) ou Oeste (W)
    decimal = -decimal;
  }
  return decimal;
};

// Componente para renderizar o mapa
const StationMap = ({ latitude, longitude, nomeEstacao }) => {
  // Converte a latitude e longitude para o formato decimal
  const latDecimal = dmsToDecimal(
    latitude.grau,
    latitude.min,
    latitude.seg,
    latitude.direcao
  );
  const lonDecimal = dmsToDecimal(
    longitude.grau,
    longitude.min,
    longitude.seg,
    longitude.direcao
  );

  return (
    <div className={styles.station_map_container}>
      <MapContainer
        center={[latDecimal, lonDecimal]} // Centraliza o mapa nas coordenadas fornecidas
        zoom={2} // Nível de zoom inicial
        minZoom={1} // Nível de zoom mínimo permitido
        maxZoom={10} // Nível de zoom máximo permitido
        maxBounds={[
          [-90, -180], // Limite mínimo (Sul, Oeste)
          [90, 180], // Limite máximo (Norte, Leste)
        ]}
        worldCopyJump={false} // Evita a replicação horizontal do mapa
        style={{ height: "200px", width: "80%", margin: "0 auto" }} // Ajusta o tamanho do mapa
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" // URL do provedor de tiles
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' // Atribuição obrigatória
        />
        {/* Cria um ponto com Circle usando unidades geográficas */}
        <Circle
          center={[latDecimal, lonDecimal]} // Localização do círculo
          radius={5} // Raio do círculo em metros
          color="blue" // Cor da borda
          fillColor="blue" // Cor do preenchimento
          fillOpacity={0.8} // Transparência do preenchimento
        >
          <Popup>
            {/* Informações no popup */}
            {nomeEstacao || "Estação Maregráfica"} <br />
            ({latDecimal.toFixed(6)}, {lonDecimal.toFixed(6)})
          </Popup>
        </Circle>
      </MapContainer>
    </div>
  );
};

export default StationMap;

